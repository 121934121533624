<template>
  <div>
    <div class="flex items-center">
      <div v-if="showTimeIcon">
        <img
          style="width: 16px; height: 16px"
          src="@/assets/image/jiqiren.png"
        />
      </div>
      <div v-if="schedules.length > 0">
        <p
          v-for="(item, index) in schedules"
          :key="item.id + index"
          class="open-schedule"
        >
          <span class="set-open"
            >{{ clockType[item.type] }}:{{ item.timeStr }}
            <el-button
              type="text"
              size="mini"
              @click.stop="cancelSet('task', scope.row, index, item.id)"
              >取消</el-button
            ></span
          >
        </p>
      </div>
      <span v-else> -- </span>
    </div>
  </div>
</template>
<script>
import { delAdScheduleUpdateBudgetTask, delAdScheduleOpenTask, delUpdateBidTask } from '@/api/adManagement.js';
const clockType = {
  1: '定时开',
  2: '定时预算',
  3: '定时出价',
  4: '定时关',
};
export default {
  props: {
    scope: {
      type: Object,
      default: () => ({
        row: {
          open_schedule: {},
        },
      }),
    },
  },
  data() {
    return {
      clockType,
    };
  },
  computed: {
    schedules: {
      get() {
        return this.scope.row.open_schedule && this.scope.row.open_schedule.schedules
          ? this.scope.row.open_schedule?.schedules
          : [];
      },
      set() {},
    },
    showTimeIcon() {
      return this.schedules.length || this.scope.row.open_schedule?.hasSchedule;
    },
  },
  methods: {
    // 取消定时
    cancelSet(type, row, index, id) {
      let _item = JSON.parse(JSON.stringify(row));
      let msg = '';
      let request = null;
      if (type === 'task') {
        msg = '确定取消定时开启 | 关闭任务吗？';
        request = delAdScheduleOpenTask;
      } else if (type == 'bid') {
        msg = '确定取消定时修改出价吗？';
        request = delUpdateBidTask;
      } else {
        msg = '确定取消定时修改预算吗？';
        request = delAdScheduleUpdateBudgetTask;
      }
      this.$confirm(msg, '提示', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let data = {
            // id: type == 'bid' ? id : row.id,
            taskId: id,
          };
          request(JSON.stringify(data)).then((res) => {
            if (res.code == 0) {
              this.$message.success('取消成功！');
              this.schedules.splice(index, 1);
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped></style>
